<template>
  <a-divider>{{ title }}</a-divider>
  <a-flex>
    <div v-if="!isEditModeEnabled">
      <div
        v-if="description"
        :style="{ display: 'block', wordBreak: 'break-all', overflow: 'auto' }"
        :innerHTML="formattedDescription" />
      <div v-else>
        Нет описания
      </div>
    </div>

    <div v-if="isEditingAvailable">
      <div v-if="!isEditModeEnabled">
        <form-outlined
          :style="{ color: '#1890ff', marginLeft: '1rem' }"
          @click="setEditMode(true)" />
      </div>

      <form v-if="isEditModeEnabled">
        <a-space align="center">
          <a-textarea
            v-model:value="newValue"
            placeholder="Описание"
            :style="{ maxWidth: '100%' }" />
          <check-circle-outlined
            :style="{ color: '#52c41a', marginLeft: '1rem' }"
            @click="changeDescription" />
          <close-circle-outlined
            :style="{ color: '#f5222d', marginLeft: '1rem' }"
            @click="cancelEditDescription" />
        </a-space>
      </form>
    </div>
  </a-flex>
</template>

<script setup>
import { ref, computed } from 'vue';
import {
  FormOutlined, CheckCircleOutlined, CloseCircleOutlined,
} from '@ant-design/icons-vue';
import { sanitizeHTML, parsedText } from '@/utils/htmlTextParser';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  description: {
    type: [String, Object],
    default: '',
  },
  value: {
    type: [String, Object],
    default: '',
  },
  isEditingAvailable: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['change-description']);

const newValue = ref(props.value ?? null);
const isEditModeEnabled = ref(false);

const setEditMode = (value) => {
  if (value) {
    newValue.value = typeof props.value === 'string' ? props.value : JSON.stringify(props.value, null, 2);
  }
  isEditModeEnabled.value = value;
};

const changeDescription = () => {
  emit('change-description', newValue.value);
  setEditMode(false);
};

const cancelEditDescription = () => setEditMode(false);

const formattedDescription = computed(() => {
  if (!props.description) {
    return 'Нет описания';
  }
  if (typeof props.description === 'string') {
    return sanitizeHTML(parsedText(props.description));
  }
  if (typeof props.description === 'object') {
    return sanitizeHTML(JSON.stringify(props.description, null, 2));
  }
  return sanitizeHTML('Некорректное описание');
});
</script>
