import { api } from '@/api';
import { httpClient } from '@/httpClient';

export default {
  namespaced: true,
  state: {
    items: [],
    isLoading: false,

    current: {
      isLoading: false,
    },
    currentTopViewUrl: null,
    designFiles: [],
    deletedItems: [],
  },
  mutations: {
    setFurniture(state, items) {
      state.items = items;
    },
    setLoading(state, loading) {
      state.isLoading = loading;
    },
    setCurrent(state, data) {
      state.current = {
        isLoading: state.current.isLoading,
        ...data,
      };
    },
    setCurrentLoading(state, loading) {
      state.current.isLoading = loading;
    },
    setDesignFiles(state, designFiles) {
      state.designFiles = designFiles;
    },
    setDeletedFurniture(state, deletedItems) {
      state.deletedItems = deletedItems;
    },
  },
  actions: {
    getDesignFiles(store) {
      store.commit('setLoading', true);

      return api.getDesignFiles().then(({ data }) => {
        store.commit('setDesignFiles', data);
        store.commit('setLoading', false);
      });
    },

    getList(store) {
      store.commit('setLoading', true);

      const params = { page: store.state.page };

      return api.getFurnitureList(params).then(({ data }) => {
        store.commit('setFurniture', data);
        store.commit('setLoading', false);
      });
    },

    async get(store, id) {
      const convertedId = Number(id);

      if (store.state.items.length === 0) {
        await store.dispatch('getList');
      }

      const furniture = store.state.items.find((item) => convertedId === item.id);

      store.commit('setCurrent', furniture);

      return furniture;
    },

    updateFurnitureParams({ state, commit }, { id, params }) {
      const requestParams = {
        x_length: params.xLength,
        y_length: params.yLength,
        z_length: params.zLength,
        z_location: params.zLocation,
        is_scalable: params.isScalable,
        is_procedural: params.isProcedural,
        styles: params.styles,
        room_types: params.roomTypes,
        spatial_anchor: params.spatialAnchor,
      };

      commit('setFurniture', state.items.map((item) => {
        if (item.id === Number(id)) {
          return {
            ...item,
            ...params,
          };
        }
        return item;
      }));
      return api.updateFurnitureParams({ id, params: requestParams });
    },

    async updateFurnitureImg({ commit, state }, { id, img, type }) {
      const fd = new FormData();
      fd.append('image', img);
      fd.append('type', type);
      const response = await httpClient.post(`/api/furniture/${id}/update_image`, fd, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      commit('setCurrent', response.data);

      commit('setFurniture', state.items.map((item) => {
        if (item.id === response.data.id) {
          return response.data;
        }

        return item;
      }));

      return true;
    },

    deleteFurnitureById(store, id) {
      return api.deleteFurniture(id);
    },

    createDesignFile(store, {
      file, name,
    }) {
      const fd = new FormData();
      fd.append('file_name', name);
      fd.append('file', file);
      return api.createDesignFile(fd).then(() => {
        store.dispatch('getDesignFiles');
      });
    },

    deleteDesignFile(store, id) {
      return api.deleteDesignFile(id).then(() => {
        store.dispatch('getDesignFiles');
      });
    },

    changeDesignFile(store, {
      id, file, name,
    }) {
      const fd = new FormData();
      fd.append('file', file);
      fd.append('file_name', name);
      return api.changeDesignFile(id, fd).then(() => {
        store.dispatch('getDesignFiles');
      });
    },

    downloadDesignFile(store, id) {
      return api.downloadDesignFile(id);
    },

    getDeletedFurnitureList(store) {
      store.commit('setLoading', true);
      return api.getDeletedFurnitureList().then(({ data }) => {
        store.commit('setDeletedFurniture', data);
        store.commit('setLoading', false);
      });
    },

    restoreFurniture(store, id) {
      return api.restoreFurniture(id).then((resp) => {
        store.dispatch('getDeletedFurnitureList');
        return resp;
      });
    },
  },
};
