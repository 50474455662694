<template>
  <div :style="{ marginBottom: '2rem' }">
    <image-carousel>
      <template #images>
        <div
          v-for="({ svg, jpg }, index) in floorLinks"
          :key="index">
          <crm-plan-2d-cards-row
            :style="{padding: '1em 3em'}"
            :svg="svg"
            :jpg="jpg" />
        </div>
      </template>
    </image-carousel>

    <a-divider v-if="layoutLinks.length">
      Результат склейки
    </a-divider>

    <image-viewer v-if="layoutLinks.length">
      <a-flex
        v-for="(layout, index) in layoutLinks"
        :key="index"
        :style="{padding: '1em 3em'}"
        wrap="wrap"
        justify="flex-start"
        gap="middle">
        <crm-plan-2d-result-card
          :image="layout.svgLayout.value"
          :title="layout.svgLayout.title" />
        <crm-plan-2d-result-card
          :image="layout.jpgLayout.value"
          :title="layout.jpgLayout.title" />
      </a-flex>
    </image-viewer>
  </div>
</template>

<script setup>
import ImageViewer from '@/Components/Common/ImageViewer.vue';
import CrmPlan2dCardsRow from '@/Components/CRM/Plans/CrmPlan2dResults/CrmPlan2dCardsRow.vue';
import CrmPlan2dResultCard from '@/Components/CRM/Plans/CrmPlan2dResults/CrmPlan2dResultCard.vue';
import _ from 'lodash';
import { ref } from 'vue';
import ImageCarousel from '@/Components/Common/ImageCarousel.vue';

const props = defineProps({
  links: {
    required: true,
    type: Object,
    default: () => {},
  },
});

const floorLinks = ref([]);
const layoutLinks = ref([]);

const initializeFloorLinks = () => {
  const [svgLinks, jpgLinks, jpgLayoutLinks, svgLayoutLinks] = Object.values(props.links);

  floorLinks.value = _.zip(svgLinks, jpgLinks).map(([svgLink, jpgLink], index) => {
    const floorNumber = index + 1;
    const floorData = {
      svg: {
        value: svgLink,
        title: `SVG Результат [${floorNumber} этаж]`,
      },
      jpg: {
        value: jpgLink,
        title: `JPG Результат [${floorNumber} этаж]`,
      },
    };
    return floorData;
  });

  layoutLinks.value = _.zip(svgLayoutLinks, jpgLayoutLinks).map(([svgLayoutLink, jpgLayoutLink]) => {
    if (svgLayoutLink && jpgLayoutLink) {
      const layoutData = {
        svgLayout: {
          value: svgLayoutLink,
          title: 'Результат склейки SVG',
        },
        jpgLayout: {
          value: jpgLayoutLink,
          title: 'Результат склейки JPG',
        },
      };
      return layoutData;
    }
    return null;
  }).filter((layout) => layout !== null);
};

initializeFloorLinks();
</script>

<style>

.ant-carousel :deep(.custom-slick-arrow) {
  font-size: 20px;
  color: #555555;
  transition: all 0.3s ease;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.custom-slick-arrow.inactive) {
  opacity: 0.3;
  cursor: auto;
  pointer-events: none;
  transition: all 0.3s ease;
}

</style>
