export const downloadFile = (href, name) => {
  if (!href) {
    console.error('URL для скачивания отсутствует');
    return;
  }

  const link = document.createElement('a');
  link.href = href;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
