import { fileDownloadConfig, httpClient } from '@/httpClient';
import { PlanType } from '@/enums/planType';
import qs from 'qs';
import config from '@/config'; // eslint-disable-line import/extensions

const getCrmPlanById = (id) => httpClient.get(`/api/crm/plans/${id}/`);

const getCrmPlan3dById = (id) => httpClient.get(`/api/crm/plans/${id}/plan_3d`);

const getToWorkAvailableCount = async ({ type }) => httpClient
  .get('/api/crm/plans/get_to_work_available_count', { params: { type } });

const getNinjaStatisticById = async (id, { fromDate, toDate }) => httpClient.get(
  `/api/crm/statistic/ninja/${id}`,
  { params: { from_date: fromDate, to_date: toDate } },
);

const getNinjaStatistic3dById = async (id, { fromDate, toDate }) => httpClient.get(
  `/api/crm/statistic/ninja_3d/${id}`,
  { params: { from_date: fromDate, to_date: toDate } },
);

const getServicePlanList = ({ page = 1, filter }) => httpClient.get('/api/plans', {
  params: {
    page,
    filter,
    sort: 'created_at',
  },
  paramsSerializer: (s) => qs.stringify(s),
});

const getServicePlanById = async (id) => httpClient.get(`/api/plans/${id}`);
const changePlanUnrealImage = ({ id, formData, headers }) => httpClient
  .post(`/api/plans/${id}/change_image`, formData, headers);

const getTagsList = () => httpClient.get('/api/crm/tags');
const createTag = (request) => httpClient.post('/api/crm/tags', request);
const editTag = ({ id, name }) => httpClient.post(`/api/crm/tags/${id}`, { name });
const deleteTag = (id) => httpClient.delete(`/api/crm/tags/${id}`);

const getUsersList = ({ page, filter, sort }) => httpClient.get('/api/users', {
  params: {
    page,
    filter,
    sort,
  },
  paramsSerializer: (s) => qs.stringify(s),
});
const getDeletedUsersList = ({ page, filter, sort }) => httpClient.get('/api/users/deleted', {
  params: {
    page,
    filter,
    sort,
  },
  paramsSerializer: (s) => qs.stringify(s),
});
const changeUserRole = (request) => httpClient.post('/api/users/change_role', request);
const changeUserName = ({ id, name }) => httpClient.patch(`/api/users/${id}/rename`, { name });
const deleteUser = (id) => httpClient.delete(`/api/users/${id}`);
const restoreUser = (id) => httpClient.patch(`/api/users/${id}/restore`);
const changeNinjaStatus = (id, ninjaExpertLevel2d, ninjaExpertLevel3d) => httpClient
  .post(
    `/api/users/${id}/change_ninja_expert_level`,
    {
      ninja_expert_level_2d: ninjaExpertLevel2d,
      ninja_expert_level_3d: ninjaExpertLevel3d,
    },
  );

const changeBetaTesterStatus = (id, betaTester) => {
  httpClient.post(`api/users/${id}/change_beta_tester_status`, { beta_tester: betaTester });
};

const changeDatasetVoterStatus = (id, datasetVoter) => {
  httpClient.post(`api/users/${id}/change_dataset_voter_status`, { dataset_voter: datasetVoter });
};

/**
 * @param {string | number} id
 * @param {boolean} value
 */
const changeCanGetPlansToWorkStatus = (id, value) => {
  httpClient.post(`api/users/${id}/change_can_get_plans_to_work_status`, { can_get_plans_to_work: value });
};

const executeCrmPlanAction = ({ planId, action }) => httpClient.post(`/api/crm/plans/${planId}/${action}`);
const deleteCrmPlan = (id) => httpClient.delete(`/api/crm/plans/${id}/delete`);
const cloneCrmPlan = (id) => httpClient.post(`/api/crm/plans/${id}/replicate`);

const changePlanStatus3d = ({ id, status }) => httpClient.post(`/api/crm/plans/${id}/status_3d/${status}`);

const getCrmPlan3dPreview = (id, presetId) => httpClient
  .post(`/api/crm/plans/${id}/get_3d_preview`, { plan_preset_id: presetId });
const getCrmPlan3dResult = (id, presetId) => httpClient
  .post(`/api/crm/plans/${id}/get_3d_result`, { plan_preset_id: presetId });
const getCrmPlan3dResultLow = (id) => httpClient
  .post(`/api/crm/plans/${id}/get_3d_low`);
const getCrmPlan3dResultHigh = (id) => httpClient
  .post(`/api/crm/plans/${id}/get_3d_high`);
const getCrmPlan3dResultUltraHigh = (id) => httpClient
  .post(`/api/crm/plans/${id}/get_3d_ultra_high`);
const getCrmPlan3dResultUnlit = (id) => httpClient
  .post(`/api/crm/plans/${id}/get_3d_unlit`);

const changeCameraNavigationMode = (id, cameraNavigation) => httpClient
  .post(`/api/plans/${id}/change_capability`, { cameraNavigation });

const getCrmPlanFurniturePlacement = (id, presetId) => httpClient
  .post(`/api/crm/plans/${id}/run_furniture_placement`, { plan_preset_id: presetId });
const changePlanStrategicData = (id, region, developer, complex, address) => {
  httpClient.post(`api/crm/plans/${id}/change_strategic_data`, {
    region, developer, complex, address,
  });
};

const getDesignFiles = () => httpClient.get('/api/furniture/design_files');
const createDesignFile = (fd) => httpClient.post('/api/furniture/design_files', fd);
const deleteDesignFile = (id) => httpClient.delete(`/api/furniture/design_files/${id}`);
const changeDesignFile = (id, fd) => httpClient.post(`/api/furniture/design_files/${id}`, fd);
const downloadDesignFile = (id) => httpClient.get(`api/furniture/design_files/${id}`);

const getFurnitureList = (params) => httpClient.get(
  '/api/furniture',
  { params, paramsSerializer: (s) => qs.stringify(s) },
);
const getDeletedFurnitureList = () => httpClient.get('/api/furniture/deleted');
const updateFurnitureParams = ({ id, params }) => httpClient.patch(`/api/furniture/${id}`, params);
const deleteFurniture = (id) => httpClient.delete(`/api/furniture/${id}`);
const restoreFurniture = (id) => httpClient.patch(`/api/furniture/${id}/restore`);
const createFurniture = (request) => {
  const fd = new FormData();

  const entries = Object.entries(request);

  entries.forEach(([key, value]) => {
    if (key === 'textures') {
      value.forEach((texture, index) => {
        fd.append(`textures[${index}][type]`, texture.type);
        fd.append(`textures[${index}][material_id]`, texture.material_id);
        fd.append(`textures[${index}][file]`, texture.file);
      });
    } else {
      fd.append(key, value);
    }
  });

  return httpClient.post('api/furniture/upload', fd);
};

const getSvgList = () => httpClient.get('/api/svg_editor/icons');
const createSvg = (data) => httpClient.post('/api/svg_editor/icons', data);
const deleteSvgByName = (directory, name) => httpClient.delete(`/api/svg_editor/icons/${directory}/${name}`);
const archiveSvgByName = (name) => httpClient.post(`/api/svg_editor/icons/${name}/archive`);
const restoreSvgByName = (name) => httpClient.patch(`/api/svg_editor/icons/${name}`);

const getApartmentList = (params) => httpClient.get(
  '/api/crm/apartments',
  { params, paramsSerializer: (s) => qs.stringify(s) },
);
const updateApartment = (id, {
  name, description, additional, region, developer, complex, address, sameNinjaFor2dAnd3dPlans,
}) => httpClient
  .patch(`/api/crm/apartments/${id}`, {
    name,
    description,
    additional,
    region,
    developer,
    complex,
    address,
    same_ninja_for_2d_and_3d_plans: sameNinjaFor2dAnd3dPlans,
  });

const setApartmentResponsibleNinja = (id, { ninjaIds }) => httpClient
  .post(`api/crm/apartments/${id}/set_responsible_ninja`, { ninja_ids: ninjaIds });

const setApartmentResponsibleNinja3d = (id, { ninjaIds }) => httpClient
  .post(`api/crm/apartments/${id}/set_responsible_ninja_3d`, { ninja_ids: ninjaIds });
const changeApartmentPriority = (id, { priority }) => httpClient
  .post(`/api/crm/apartments/${id}/change_priority`, { priority });

const getApartmentsPlansList = (id, type) => httpClient.get(`/api/crm/apartments/${id}/plans/${type}`);

const getNinjaStatisticPlans = (id) => httpClient.get(`api/crm/statistic/ninja/${id}/plans`);
const getSameCrmPlans = (id, type = 0) => httpClient.get(`/api/crm/plans/${id}/same?type=${type}`);
const getOriginalPlansArchive = (id) => httpClient
  .get(`/api/crm/apartments/${id}/download_orginal_plans`, fileDownloadConfig);
const enableSelectedPlans3d = ({ ids }) => {
  httpClient
    .post('api/crm/plans/batch/enable_3d', { ids });
};
const setApartmentPlansPreset = ({ newPresetId, ids }) => {
  httpClient.post('api/crm/plans/batch/set_preset', { preset_id: newPresetId, ids });
};

const mergeCrmPlan = (id, { type, crmPlanId }) => httpClient
  .post(`/api/crm/plans/${id}/merge`, { type, crm_plan_id: crmPlanId });

const getNinja2dStatisticPlans = (id, { afterDate, beforeDate }) => httpClient
  .get(`api/crm/statistic/ninja/${id}/plans`, {
    params: {
      filter: {
        after_date: afterDate,
        before_date: beforeDate,
      },
    },
    paramsSerializer: (s) => qs.stringify(s),
  });
const getNinja3dStatisticPlans = (id, { afterDate, beforeDate }) => httpClient
  .get(`api/crm/statistic/ninja_3d/${id}/plans`, {
    params: {
      filter: {
        after_date: afterDate,
        before_date: beforeDate,
      },
    },
    paramsSerializer: (s) => qs.stringify(s),
  });
const getPaticularNinja2dStatisticFile = (id, { afterDate, beforeDate }) => httpClient
  .get(
    `api/crm/statistic/ninja/${id}/excel`,

    {

      ...fileDownloadConfig,
      params: {
        filter: {
          after_date: afterDate,
          before_date: beforeDate,
        },
      },
      paramsSerializer: (s) => qs.stringify(s),
    },
  );

const getPaticularNinja3dStatisticFile = (id, { afterDate, beforeDate }) => httpClient
  .get(
    `api/crm/statistic/ninja_3d/${id}/excel`,

    {
      ...fileDownloadConfig,
      params: {
        filter: {
          after_date: afterDate,
          before_date: beforeDate,
        },
      },
      paramsSerializer: (s) => qs.stringify(s),
    },

  );

const getStylesListForFurniture = () => httpClient.get('api/plans/styles');
const createStyleForFurniture = (request) => httpClient.post('api/plans/styles', request);
const editStyleForFurniture = ({ id, name }) => httpClient.patch(`api/plans/styles/${id}`, { name });
const deleteStyleForFurniture = (id) => httpClient.delete(`/api/plans/styles/${id}`);

const getRoomTypesList = () => httpClient.get('api/furniture/room_types');
const createRoomType = (request) => httpClient.post('api/furniture/room_types', request);
const editRoomType = ({ id, name }) => httpClient.patch(`api/furniture/room_types/${id}`, { name });
const deleteRoomType = (id) => httpClient.delete(`/api/furniture/room_types/${id}`);
const createPreset = (request) => httpClient.post('api/crm/preset', request);
const getPresets = () => httpClient.get('api/crm/preset');
const editPreset = ({
  id, name, planStyleId, furnitureDesignFileId,
}) => httpClient.patch(`api/crm/preset/${id}`, {
  name,
  plan_style_id: planStyleId,
  furniture_design_file_id: furnitureDesignFileId,
});
const changeCrmPlanPreset = ({
  id, presetId,
}) => httpClient.post(`api/crm/plans/${id}/change_preset/${presetId}`, {
  preset_id: presetId,
});
const deletePreset = (id) => httpClient.delete(`/api/crm/preset/${id}`);

const getUnrealEnvironment = () => httpClient.get('/api/plans/unreal_environment');
const createUnrealEnvironment = (formData) => httpClient.post('/api/plans/unreal_environment', formData);
const editUnrealEnvironment = (request) => httpClient.patch(`/api/plans/unreal_environment/${request.id}`, request)
  .then((response) => response.data);

const deleteUnrealEnvironment = (id) => httpClient.delete(`/api/plans/unreal_environment/${id}`);
const changeUnrealEnvironment = ({
  id, unrealEnvironmentId,
}) => httpClient.post(`api/crm/plans/${id}/set_unreal_environment/${unrealEnvironmentId}`, {
  unreal_environment_id: unrealEnvironmentId,
});

const getBrandingsList = () => httpClient.get('api/plans/widget_branding');
const createBranding = (request) => httpClient.post('api/plans/widget_branding', request);
const editBranding = (request) => httpClient.post(`api/plans/widget_branding/${request.id}`, request);
const deleteBranding = (id) => httpClient.delete(`api/plans/widget_branding/${id}`, { widgetBranding: id });

const getDesignFile = (id) => httpClient.get(`api/furniture/design_files/${id}`);
const createNewDesignFile = (id, newName, postData) => httpClient.post(`api/furniture/design_files/${id}/change`, {
  new_furniture_design_file_name: newName,
  furniture: postData,
});

const getCuttingPlansList = (params) => httpClient.get('/api/crm/plans/cropping', {
  params,
  paramsSerializer: (s) => qs.stringify(s),
});
const startPlanCutting = (planId) => httpClient.post(`/api/crm/plans/cropping/${planId}/start`, { id: planId });
const getToWorkPlanCutting = () => httpClient.post('/api/crm/plans/cropping/get_to_work');
const showCuttingPlan = (planId) => httpClient.get(`/api/crm/plans/cropping/${planId}`, { id: planId });

const postponeCuttingPlan = (planId) => httpClient.post(`/api/crm/plans/cropping/${planId}/postpone`, { id: planId });
const unpostponeCuttingPlan = (id) => httpClient.post(`/api/crm/plans/cropping/${id}/unpostpone`);

const returnCuttingPlan = (id) => httpClient.post(`/api/crm/plans/cropping/${id}/return`);

const declineCuttingPlan = (planId) => httpClient.post(`/api/crm/plans/${planId}/decline`, { id: planId });

const uploadImagesCuttingPlan = (planId, formData) => httpClient
  .post(`/api/crm/plans/cropping/${planId}/upload_images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    id: planId,
  });

const sendCuttingPlanForProcessing = (planId, fd) => httpClient
  .post(`/api/crm/plans/cropping/${planId}/send_for_processing`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    id: planId,
  });

const submitCuttingPlanForProcessing = (planId, fd) => httpClient
  .post(`api/crm/plans/cropping/${planId}/submit_for_processing`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    id: planId,
  });

const reloadFromStep = (id, { step }) => httpClient
  .post(`/api/plans/${id}/reload_step`, { step });

const resetFromStep = (id, { step }) => httpClient
  .post(`/api/plans/${id}/reset_to_error`, { step });

const getPlans = (type, params) => {
  switch (type) {
    case PlanType.Type2d:
      return httpClient.get('/api/crm/plans', { params });
    case PlanType.Type3d:
      return httpClient.get('/api/crm/plans/list_3d', { params });
    default:
      throw Error('Undefined type');
  }
};

const getServicePlans = async (type, {
  status, userIds, tagIds, name, region, developer, complex, address, page, dates, crmTagName, perPage, withSvg,
}) => httpClient
  .get(`/api/crm/plans${type === PlanType.Type3d ? '/list_3d' : ''}`, {
    params: {
      filter: {
        status,
        user_ids: userIds,
        tag_ids: tagIds,
        name: name?.split('|'),
        region,
        developer,
        complex,
        address,
        after_date: dates?.[0]?.toISOString() ?? null,
        before_date: dates?.[1]?.toISOString() ?? null,
        crm_tag_name: crmTagName,
      },
      page,
      per_page: perPage,
      with_svg: withSvg,
    },
    paramsSerializer: (s) => qs.stringify(s),

  });

const getPlanMaskToWork = (dataset) => httpClient
  .get(`/api/neural_dataset_selection_service/datasets/${dataset}/get_plan_to_work`);
const datasetSelectionPlans = () => httpClient
  .get('/api/neural_dataset_selection_service/');
const datasetSelectionDatasets = () => httpClient
  .get('/api/neural_dataset_selection_service/datasets');
const datasetSelectionPlansReject = (id, dataset) => httpClient
  .post(`/api/neural_dataset_selection_service/datasets/${dataset}/dataset_plans/${id}/reject`);
const datasetSelectionPlansAccept = (id, dataset) => httpClient
  .post(`/api/neural_dataset_selection_service/datasets/${dataset}/dataset_plans/${id}/accept`);
const datasetSelectionPlansReport = (id, dataset) => httpClient
  .post(`/api/neural_dataset_selection_service/datasets/${dataset}/dataset_plans/${id}/report`);

const getStrategicData = (type) => httpClient.get(`/api/crm/${type}/strategic_data`);

const getStyleMaterials = () => httpClient.get('/api/furniture/style_materials');
const createStyleMaterial = ({ name, textures }) => {
  const fd = new FormData();

  fd.append('name', name);

  textures.forEach(({ type, texture }, index) => {
    fd.append(`textures[${index}][type]`, type);
    fd.append(`textures[${index}][texture]`, texture);
  });

  return httpClient.post('/api/furniture/style_materials', fd);
};
const deleteStyleMaterial = (id) => httpClient.delete(`/api/furniture/style_materials/${id}`);

const createRoomStyleItem = ({
  type, styleId, roomTypeId, styleMaterialIds,
}) => httpClient.post('/api/plans/room_style_items', {
  type,
  style_id: styleId,
  room_type_id: roomTypeId,
  style_material_ids: styleMaterialIds,
});

const deleteRoomStyleItem = (id) => httpClient.delete(`/api/plans/room_style_items/${id}`);

const getWidgetVariant = (widgetVariantId) => httpClient
  .get(`api/crm/plans/widget/variant/${widgetVariantId}`, { widgetVariant_id: widgetVariantId });

const createWidgetVariant = (addVariantsForm, crmPlanId) => httpClient
  .post('api/crm/plans/widget/variant', { variants: addVariantsForm.variants, crm_plan_id: crmPlanId });

const updateWidgetVariant = (requestData, widgetVariantId) => httpClient
  .post(`api/crm/plans/widget/variant/${widgetVariantId}`, {
    widgetVariant_id: widgetVariantId,
    variants: requestData.variants,
  });

const deleteWidgetVariant = (widgetVariantId) => httpClient
  .delete(`api/crm/plans/widget/variant/${widgetVariantId}`, { widgetVariant_id: widgetVariantId });

const getCustomersList = () => httpClient.get('api/users/customers');
const createCustomer = (request) => httpClient.post('api/users/customers', request);
const editCustomer = (request) => httpClient.post(`api/users/customers/${request.id}`, request);
const deleteCustomer = (id) => httpClient.delete(`api/users/customers/${id}`);

const getWidgetLinkForCrmId = (id) => httpClient.get(`api/crm/plans/${id}/widget`);
const getWidgetLinkForPlanId = (id) => httpClient.get(`api/plans/${id}/v2/widget`);

const findSimilarPlansAi = async (formData) => {
  const response = await httpClient.post('/api/v1/bovw/floorplan/find_similar/', formData, {
    baseURL: config.apiBovwUrl,
  });
  return response.data;
};

export const api = {
  getCrmPlanById,
  getCrmPlan3dById,

  getServicePlanList,
  getServicePlanById,
  changePlanUnrealImage,

  getToWorkAvailableCount,
  getNinjaStatisticById,
  getNinjaStatistic3dById,

  getTagsList,
  createTag,
  editTag,
  deleteTag,

  getUsersList,
  getDeletedUsersList,
  changeUserRole,
  changeUserName,
  deleteUser,
  restoreUser,
  changeNinjaStatus,
  changeBetaTesterStatus,
  changeDatasetVoterStatus,
  changeCanGetPlansToWorkStatus,

  getDesignFiles,
  createDesignFile,
  deleteDesignFile,
  changeDesignFile,
  downloadDesignFile,

  getFurnitureList,
  getDeletedFurnitureList,
  updateFurnitureParams,
  deleteFurniture,
  restoreFurniture,
  createFurniture,

  executeCrmPlanAction,
  deleteCrmPlan,
  cloneCrmPlan,

  createSvg,
  getSvgList,
  deleteSvgByName,
  archiveSvgByName,
  restoreSvgByName,

  changePlanStatus3d,
  getCrmPlan3dPreview,
  getCrmPlan3dResult,
  getCrmPlan3dResultLow,
  getCrmPlan3dResultHigh,
  getCrmPlan3dResultUltraHigh,
  getCrmPlan3dResultUnlit,
  changeCameraNavigationMode,
  getCrmPlanFurniturePlacement,
  changePlanStrategicData,

  getApartmentList,
  updateApartment,
  setApartmentResponsibleNinja,
  setApartmentResponsibleNinja3d,
  changeApartmentPriority,

  getApartmentsPlansList,

  enableSelectedPlans3d,
  setApartmentPlansPreset,

  getNinjaStatisticPlans,
  getSameCrmPlans,
  getOriginalPlansArchive,
  mergeCrmPlan,

  getStylesListForFurniture,
  createStyleForFurniture,
  editStyleForFurniture,
  deleteStyleForFurniture,

  getRoomTypesList,
  createRoomType,
  editRoomType,
  deleteRoomType,
  getNinja2dStatisticPlans,
  getNinja3dStatisticPlans,

  getPaticularNinja2dStatisticFile,
  getPaticularNinja3dStatisticFile,

  createPreset,
  getPresets,
  editPreset,
  changeCrmPlanPreset,
  deletePreset,

  getUnrealEnvironment,
  createUnrealEnvironment,
  editUnrealEnvironment,
  deleteUnrealEnvironment,
  changeUnrealEnvironment,

  getBrandingsList,
  createBranding,
  editBranding,
  deleteBranding,

  getDesignFile,
  createNewDesignFile,

  getCuttingPlansList,
  startPlanCutting,
  getToWorkPlanCutting,
  showCuttingPlan,
  sendCuttingPlanForProcessing,
  postponeCuttingPlan,
  unpostponeCuttingPlan,
  returnCuttingPlan,
  declineCuttingPlan,
  uploadImagesCuttingPlan,
  submitCuttingPlanForProcessing,

  getPlans,
  getServicePlans,
  reloadFromStep,
  getPlanMaskToWork,
  datasetSelectionPlans,
  datasetSelectionDatasets,
  datasetSelectionPlansReject,
  datasetSelectionPlansAccept,
  datasetSelectionPlansReport,

  resetFromStep,

  getStrategicData,
  getStyleMaterials,
  createStyleMaterial,
  deleteStyleMaterial,

  createRoomStyleItem,
  deleteRoomStyleItem,

  getWidgetVariant,
  createWidgetVariant,
  updateWidgetVariant,
  deleteWidgetVariant,

  getCustomersList,
  createCustomer,
  editCustomer,
  deleteCustomer,

  getWidgetLinkForCrmId,
  getWidgetLinkForPlanId,

  findSimilarPlansAi,
};
