export const RtxPreset = {
  'No RTX Preview': -1,
  Preview: 0,
  Low: 1,
  Medium: 2,
  High: 3,
  'Ultra High': 4,
  Epic: 5,
  Unlit: 6,
};
