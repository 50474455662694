<template>
  <container-wrapper-full>
    <a-skeleton
      v-if="isLoading"
      active />

    <div v-else>
      <plan-heading-wrapper
        :id="String(plan.id)"
        :plan-name="plan.name || ''"
        :apartment-name="plan.apartment?.name"
        :apartment-id="plan.apartment?.id"
        :can-edit="!isUserSupervisor"
        @submit-name="changeName">
        <template #status>
          <crm-plan-info
            :is-user-supervisor="isUserSupervisor"
            :is-merged="Boolean(plan.merged_from_plan_id)"
            :plan="plan"
            :tags-list="tagsList"
            :can-change-ninja="canChangeNinja"
            :options-tags="false"
            :attach-tags-to-plan="attachTagsToPlan"
            :unreal-rtx-preset="plan?.plan?.options?.unreal_rtx_preset"
            route-for-crm-plan="/crm/plans/3d/" />
        </template>

        <template #actions>
          <actions-buttons
            :plan-status-furniture="plan.plan.status.furniture"
            :plan-status-unreal="plan.plan.status.unreal"
            :plan-id="plan.plan.id"
            :show-buttons="isUserAdmin"
            :buttons="buttons"
            @button-click="onButtonClick" />
        </template>
      </plan-heading-wrapper>

      <plan-main
        :plan="plan"
        :plan-id="id"
        :is-user-supervisor="isUserSupervisor"
        :is-user-admin="isUserAdmin"
        :is-user-ninja="isUserNinja"
        :plan-type="type"
        :service-plan-id="plan?.plan?.id"
        :compare-data="compareCarousel"
        :load-data="loadData" />

      <section
        v-if="!isUserSupervisor"
        :style="{ marginBottom: '1.25rem' }">
        <a-row :gutter="[16, 16]">
          <a-divider>Пресеты</a-divider>
          <a-select
            v-model:value="presetId"
            :style="{ width: '100%' }"
            @change="changePlan3dPreset">
            <a-select-option
              v-if="!plan.preset_id"
              selected="selected"
              value>
              Выберите пресет (По умолчанию: {{ defaultPreset?.name }})
            </a-select-option>
            <a-select-option
              v-for="(preset) in presetsList"
              :key="preset.id"
              :value="preset.id">
              {{ preset.name }}
            </a-select-option>
          </a-select>
          <a-divider v-if="isEnvironmentVisible">
            Окружение
            <span v-if="plan.unreal_environment_id === null">- {{ defaultUnrealEnvironment }}</span>
          </a-divider>

          <auto-complete-filter
            v-if="isEnvironmentVisible"
            v-model:value="searchName"
            :search-list="unrealEnvironmentList"
            @select="handleAutoCompleteSelect" />
        </a-row>
      </section>

      <section
        v-if="!isUserSupervisor"
        :style="{ marginBottom: '1.25rem' }">
        <a-row :gutter="[16, 16]">
          <a-divider>Расстановка мебели ({{ getStageStatusText(furnitureStatus) }})</a-divider>
          <a-col
            :md="16"
            :span="24">
            <a-button
              :style="{ width: '100%' }"
              :href="plan.links.furniture.editor || plan.links.neural.editor"
              target="_blank"
              rel="noopener noreferrer">
              Конструктор мебели
            </a-button>
          </a-col>
          <a-col
            :md="8"
            :span="24">
            <a-button
              :style="{ width: '100%' }"
              :disabled="isButtonDisabled"
              @click="placeFurniture">
              Расставить мебель
            </a-button>
          </a-col>
        </a-row>
      </section>

      <section
        v-if="!isUserSupervisor"
        :style="{ marginBottom: '1.25rem' }">
        <a-row :gutter="[16, 16]">
          <a-divider>Превью/Результат ({{ getStageStatusText(unrealStatus) }})</a-divider>
          <a-col
            :md="8"
            :span="24">
            <a-button
              :style="{ width: '100%' }"
              :disabled="isButtonDisabled"
              @click="generatePreview">
              Генерировать превью
            </a-button>
          </a-col>
          <a-col
            :md="8"
            :span="24">
            <a-button
              v-if="!isGenerationCrmPlan3dResultHighVisible"
              :style="{ width: '100%' }"
              :disabled="isButtonDisabled"
              @click="generateResult">
              Генерировать результат
            </a-button>

            <a-select
              v-else
              :disabled="isButtonDisabled"
              placeholder="Генерировать результат (качество)"
              :style="{ width: '100%' }"
              @change="handleResultQualityChange">
              <a-select-option
                v-for="quality in Object.keys(crmPlan3dResultQuality)"
                :key="quality"
                :value="quality">
                {{ quality }}
              </a-select-option>
            </a-select>
          </a-col>

          <a-col
            v-if="isCameraNavigationSelectVisible"
            :md="8"
            :span="24">
            <a-select
              v-model="cameraNavigation"
              placeholder="Сменить режим навигации"
              :style="{ width: '100%', marginBottom: '1rem' }"
              :options="filteredCameraNavigationOptions"
              @change="changeCameraNavigationMode" />
          </a-col>
        </a-row>
      </section>

      <section
        v-if="isWidgetLinkConstructorVisible"
        :style="{marginBottom: '1rem'}">
        <widget-link-constructor
          :plan-id="plan.id"
          :url="url"
          query-plan="crmPlanId" />
      </section>

      <section
        v-if="unrealStatus === 2"
        :style="{ marginBottom: '1.25rem' }">
        <a-collapse :style="{ width: '100%' }">
          <a-collapse-panel
            key="1"
            header="Смотреть"
            :style="{ width: '100%' }">
            <template #extra>
              <a-tooltip>
                <template #title>
                  Открыть в новой вкладке
                </template>

                <a-button
                  target="_blank"
                  :href="widgetUrl"
                  @click="(e) => e.stopPropagation()">
                  <template #icon>
                    <link-outlined />
                  </template>
                </a-button>
              </a-tooltip>
            </template>

            <iframe
              title="plan-widget"
              :style="{ margin: 'auto', border: 'none' }"
              :src="widgetUrl"
              width="100%"
              height="800" />
          </a-collapse-panel>
        </a-collapse>
      </section>

      <a-divider type="horizontal" />

      <section v-if="plan.apartment?.id">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <loaded-files-list
              :files="apartmentFiles"
              :files-sum="apartmentFiles?.length"
              :loading="isSourcesArchiveCreating"
              @download="downloadSources" />
          </a-col>

          <a-col
            v-if="apartmentLinkedPlans.length !== 0"
            :span="24">
            <linked-plans
              :data="apartmentLinkedPlans"
              :type="1"
              @change="null" />
          </a-col>
        </a-row>
      </section>

      <section>
        <a-row
          type="flex"
          align="top"
          :style="{ gap: '1rem' }">
          <plan-status-logs
            :id="plan.id"
            :type="PlanType.Type3d" />

          <plan-comments
            :id="plan.id"
            :auth="authUser"
            :type="PlanType.Type3d" />
        </a-row>
      </section>

      <change-ninja-modal
        :plan-id="plan?.id"
        action="crm/changeCrmPlanNinja3d" />
    </div>
  </container-wrapper-full>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  computed, onBeforeUnmount, onMounted, ref, watchEffect,
} from 'vue';
import {
  LinkOutlined,
} from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';

import { api } from '@/api';
import { Role } from '@/enums/roles';
import { PlanType } from '@/enums/planType';
import { useTimer } from '@/helpers/timer';
import { StepStatus } from '@/enums/StepStatus';
import { getPlanActionsForUser, executeAction } from '@/composables/planActions';
import { getWidgetRoute } from '@/router/helpers';

import ContainerWrapperFull from '@/Components/Common/ContainerWrapperFull.vue';
import PlanHeadingWrapper from '@/Components/Service/Plans/PlanHeadingWrapper.vue';
import ChangeNinjaModal from '@/Components/CRM/Plans/Modals/ChangeNinjaModal.vue';
import PlanStatusLogs from '@/Components/CRM/Plans/StatusLogs.vue';
import PlanComments from '@/Components/CRM/Plans/Comments.vue';
import CrmPlanInfo from '@/Components/CRM/Plans/CrmPlanInfo.vue';
import PlanMain from '@/Components/CRM/Plans/PlanMain.vue';
import LoadedFilesList from '@/Components/CRM/Apartments/LoadedFilesList.vue';
import ActionsButtons from '@/Components/CRM/Plans/ActionsButtons.vue';
import widgetLinkConstructor from '@/Components/Common/widgetLinkConstructor.vue';
import LinkedPlans from '@/Components/CRM/Apartments/LinkedPlans.vue';
import getFlag from '@/helpers/getFlag';
import { crmPlan3dResultQuality } from '@/enums/crmPlan3dResultQuality';
import { getFilesAsNameAndUrl } from '@/utils/getFilesAsNameAndUrl';
import { rotationModes } from '@/enums/rotationMode';
import AutoCompleteFilter from '@/Components/Service/Substances/Forms/AutoCompleteFilter.vue';
import { useCameraNavigationOptions } from '@/composables/useCameraNavigationOptions';

const getStageStatusText = (status) => {
  switch (status) {
    case 1:
      return 'В процессе';
    case 2:
      return 'Выполнено';
    case -1:
      return 'Ошибка';
    default:
      return 'Не выполнялось';
  }
};

const props = defineProps({
  id: {
    default: null,
    type: Number,
  },
});

const router = useRouter();
const route = useRoute();
const store = useStore();

const id = computed(() => Number(props.id || route.params.id));
const plan = computed(() => store.state.crm.currentPlan);

const authUserRoles = computed(() => store.state.auth.currentUser.roles.map(({ name }) => name.toLowerCase()));
const isUserAdmin = computed(() => authUserRoles.value.includes('admin'));
const isUserNinja = computed(() => authUserRoles.value.includes('ninja'));

const style = ref('');
const isEnvironmentVisible = getFlag('isEnvironmentVisible');
const isGenerationCrmPlan3dResultHighVisible = getFlag('isGenerationCrmPlan3dResultHighVisible');
const isWidgetLinkConstructorVisible = getFlag('isWidgetLinkConstructorVisible');

const presetId = ref('');
const unrealEnvironmentId = ref('');
const selectedPreset = ref('');
const selectedUnrealEnvironment = ref('');
const cameraNavigation = ref('');

store.dispatch('substances/getPresetsList');
store.dispatch('substances/getUnrealEnvironment');

const presetsList = computed(() => store.state.substances.presetsList);

/**
     * NOTE: Делается не через импорт rotationModes из hart-estate-widget
     * для того, чтобы на странице не грузился весь виджет.
     *
     * TODO: Заменить, когда оптимизируем виджет и его экспорты
     */
const widgetUrl = computed(() => getWidgetRoute({
  crmPlanId: id.value,
  rotationMode: rotationModes.THREESIXTY,
  lang: 'ru',
}));

store.dispatch('crm/getTagsList');

const loadData = () => store.dispatch('crm/getCrmPlan3dById', id.value);

loadData();

watchEffect(() => {
  if (id.value && route.name === 'crm-plans3d-show') {
    store.dispatch('crm/getCrmPlan3dById', id.value);
  }
});

const update = () => store.dispatch('crm/getCrmPlan3dByIdWithoutLoading', id.value);

const { timer, resetTimer } = useTimer();

const furnitureStatus = computed(() => store.state.crm.currentPlan.plan?.status?.furniture);
const unrealStatus = computed(() => store.state.crm.currentPlan.plan?.status?.unreal);

const blockStatuses = [StepStatus.WAIT, StepStatus.PROCESS];

const isButtonDisabled = computed(() => {
  const { status } = store.state.crm.currentPlan.plan;
  return blockStatuses.includes(status?.furniture) || blockStatuses.includes(status?.unreal);
});

const setTimer = () => {
  const loadDataIntervalInSeconds = 5;

  timer.value = setInterval(() => {
    const activeSteps = [furnitureStatus.value, unrealStatus.value].filter((item) => blockStatuses.includes(item));

    if (activeSteps.length === 0 || isNaN(id.value)) {
      resetTimer();
      return;
    }

    update();
  }, loadDataIntervalInSeconds * 1000);
};

onMounted(setTimer);
onBeforeUnmount(resetTimer);

store.dispatch('crm/getTagsList');

const buttons = computed(() => getPlanActionsForUser(PlanType.Type3d, store.state.crm.currentPlan, store
  .state.auth.currentUser));

const confirmText = 'Отправить в обработку?';

const processResultGeneration = (response, statusType) => {
  const currentPlan = response.data;
  currentPlan.plan.status[statusType] = StepStatus.PROCESS;
  store.commit('crm/setCurrentPlan', currentPlan);
  location.reload();
};

const generatePreview = () => {
  Modal.confirm({
    title: `${confirmText}`,
    cancelText: 'Нет',
    okText: 'Да',
    centered: true,
    onOk: () => {
      api.getCrmPlan3dPreview(id.value, style.value === '' ? undefined : style.value)
        .then((response) => processResultGeneration(response, unrealStatus));
    },
  });
};

const generateResult = () => {
  Modal.confirm({
    title: `${confirmText}`,
    cancelText: 'Нет',
    okText: 'Да',
    centered: true,
    onOk: () => {
      api.getCrmPlan3dResult(id.value, style.value === '' ? undefined : style.value)
        .then((response) => processResultGeneration(response, unrealStatus));
    },
  });
};

const handleResultQualityChange = (quality) => {
  if (quality === 'Medium') {
    api.getCrmPlan3dResult(id.value, presetId.value)
      .then((response) => processResultGeneration(response, unrealStatus));
  } else if (quality === 'High') {
    api.getCrmPlan3dResultHigh(id.value)
      .then((response) => processResultGeneration(response, unrealStatus));
  } else if (quality === 'UltraHigh') {
    api.getCrmPlan3dResultUltraHigh(id.value)
      .then((response) => processResultGeneration(response, unrealStatus));
  } else if (quality === 'Low') {
    api.getCrmPlan3dResultLow(id.value)
      .then((response) => processResultGeneration(response, unrealStatus));
  } else if (quality === 'Unlit') {
    api.getCrmPlan3dResultUnlit(id.value)
      .then((response) => processResultGeneration(response, unrealStatus));
  }
};

const changeCameraNavigationMode = (value) => {
  cameraNavigation.value = value;
  api.changeCameraNavigationMode(plan.value.plan.id, value)
    .then(() => {
      store.dispatch('crm/getCrmPlan3dById', plan.value.id);
    });
};

const placeFurniture = () => {
  Modal.confirm({
    title: `${confirmText}`,
    cancelText: 'Нет',
    okText: 'Да',
    centered: true,
    onOk: () => {
      const args = presetId.value ? [presetId.value] : [];

      api.getCrmPlanFurniturePlacement(id.value, ...args)
        .then((response) => {
          processResultGeneration(response, furnitureStatus);
        });
    },
  });
};

const onButtonClick = (action) => {
  executeAction(action, id.value, PlanType.Type3d, update, router);
};

watchEffect(() => {
  if (plan.value && plan.value.preset_id !== undefined && plan.value.unreal_environment_id !== undefined) {
    presetId.value = plan.value.preset_id;
    unrealEnvironmentId.value = plan.value.unreal_environment_id;
  }
});

const changePlan3dPreset = () => {
  api.changeCrmPlanPreset({ id: id.value, presetId: presetId.value })
    .then(() => {
      selectedPreset.value = presetsList.value.find((preset) => preset.id === presetId.value);
    });
};

const searchName = ref('');

const unrealEnvironmentList = computed(() => {
  const environments = store.state.substances.unrealEnvironment;
  const seenNames = new Set();
  return environments.filter((env) => {
    const nameNotEmpty = env.name && env.name.trim() !== '';
    const notArchived = env.deleted_at === null;
    const uniqueName = !seenNames.has(env.name);
    if (nameNotEmpty && notArchived && uniqueName) {
      seenNames.add(env.name);
      return true;
    }
    return false;
  });
});

const changePlan3dUnrealEnvironment = () => {
  api.changeUnrealEnvironment({ id: id.value, unrealEnvironmentId: unrealEnvironmentId.value })
    .then(() => {
      selectedUnrealEnvironment.value = unrealEnvironmentList.value
        .find((unrealEnvironment) => unrealEnvironment.id === unrealEnvironmentId.value);
    });
};

const handleAutoCompleteSelect = (value) => {
  searchName.value = value;
  const selectedEnvironment = unrealEnvironmentList.value.find((env) => env.name === value);
  if (selectedEnvironment) {
    unrealEnvironmentId.value = selectedEnvironment.id;
    changePlan3dUnrealEnvironment();
  }
};

watchEffect(() => {
  const environment = unrealEnvironmentList.value.find((env) => env.id === unrealEnvironmentId.value);
  if (environment) {
    searchName.value = environment.name;
  }
});

const url = api.getWidgetLinkForCrmId(id.value);

const isLoading = computed(() => store.state.crm.isCurrentPlanLoading);
const tagsList = computed(() => store.state.crm.tagsList);
const authUser = computed(() => store.state.auth.currentUser);
const type = PlanType.Type3d;
const attachTagsToPlan = (tagsIds) => store.dispatch('crm/attachTagsToPlan', { id: id.value, tagsIds }).finally(update);
const changeName = (name) => store.dispatch('crm/changePlanName', { id: id.value, name });

const isUserSupervisor = store.getters['auth/currentUserHasRoles'](Role.Supervisor);

const canChangeNinja = computed(() => {
  const currentUserHasRoles = store.getters['auth/currentUserHasRoles'];

  if (currentUserHasRoles(Role.Admin)) {
    return true;
  }

  if (!currentUserHasRoles(Role.Manager)) {
    return false;
  }

  return store.state.crm.currentPlan.manager?.id === store.state.auth.currentUser.id;
});

const isSourcesArchiveCreating = computed(() => store.state.crm.apartments.isSourcesArchiveCreating);
const downloadSources = () => store.dispatch('crm/apartments/downloadSourcesArchive', plan.value?.apartment?.id);
const apartmentFiles = computed(() => getFilesAsNameAndUrl(plan.value?.apartment?.files || []));
const apartmentLinkedPlans = computed(() => {
  const linkedPlans = plan.value?.apartment?.linked_plans_3d;
  if (!linkedPlans) {
    return [];
  }

  return linkedPlans.filter((row) => row.includes(plan.value.id));
});
const defaultPreset = computed(() => store.state.substances.defaultPreset);
const defaultUnrealEnvironment = computed(() => store.state.substances.defaultUnrealEnvironment);

const {
  filteredOptions: filteredCameraNavigationOptions,
  isSelectVisible: isCameraNavigationSelectVisible,
} = useCameraNavigationOptions();

const compareCarousel = computed(() => ({
  originals: plan.value?.pictures || [],
  svgs: plan.value?.links?.svg_editor_images || [],
  topViews: plan.value?.links?.top_view_images || [],
}));

</script>

<style>
.plan-main-image {
  width: calc(100% / 3);
}
@media (max-width: 767px) {
  .plan-main-image {
    width: 100%;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.ant-modal {
  top: 0 !important;
  max-width: 100% !important;
}

</style>
