<template>
  <container-wrapper-full>
    <plan-heading
      v-if="!loading"
      :id="String(planData.id)"
      :plan-name="planData.name || ''"
      :apartment-name="planData.apartment_name"
      :apartment-id="planData.apartment_id"
      :can-edit="!isUserSupervisor"
      @submit-name="changeName">
      <template #status>
        <crm-plan-info
          :is-user-supervisor="isUserSupervisor"
          :is-merged="Boolean(planData.merged_from_plan_id)"
          :plan="plan"
          :tags-list="tagsList"
          :can-change-ninja="canChangeNinja"
          :options-tags="true"
          :plan-data="planData"
          :attach-tags-to-plan="attachTagsToPlan"
          :unreal-rtx-preset="plan?.plan?.options?.unreal_rtx_preset"
          route-for-crm-plan="/crm/plans/"
          @change-ninja-success="loadData" />
      </template>

      <template #actions>
        <actions-buttons
          :buttons="buttons"
          @button-click="onButtonClick" />
      </template>
    </plan-heading>

    <plan-main
      :plan="planData"
      :plan-id="planId"
      :is-user-supervisor="isUserSupervisor"
      :is-user-admin="isUserAdmin"
      :is-user-ninja="isUserNinja"
      :plan-type="type"
      :service-plan-id="planData?.plan_id"
      :compare-data="compareCarousel"
      :load-data="loadData" />

    <a-skeleton
      v-if="loading"
      active
      :paragraph="{ rows: 7 }" />

    <section
      v-if="!loading && (planData.links.svg_editor.jpg
        || planData.links.svg_editor.svg
        || planData.links.svg_editor.svg_layout
        || planData.links.svg_editor.jpg_layout)"
      id="svg-editor-result">
      <a-divider type="horizontal" />
      <crm-plan-2d-results :links="plan?.links?.svg_editor" />
    </section>

    <section v-if="planData.apartment_id">
      <a-row :gutter="[16,16]">
        <a-col
          v-if="planData.apartment_id"
          :span="24">
          <loaded-files-list
            :files="planData.apartment_files"
            :files-sum="planData.apartment_files?.length"
            :loading="isSourcesArchiveCreating"
            @download="downloadSources" />
        </a-col>

        <a-col
          v-if="planData.apartmentLinkedPlans.length !== 0"
          :span="24">
          <linked-plans
            :data="planData.apartmentLinkedPlans"
            :type="0"
            @change="null" />
        </a-col>
      </a-row>
    </section>

    <a-row
      type="flex"
      align="top"
      :style="{ gap: '1rem' }">
      <plan-status-logs
        :id="planId"
        :type="type" />

      <plans-comments
        :id="planId"
        :type="type"
        :auth="authUser" />
    </a-row>

    <change-ninja-modal
      :plan-id="planData.id"
      action="crm/changeCrmPlanNinja" />
  </container-wrapper-full>
</template>

<script setup>
import {
  computed, onMounted, ref, watchEffect,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { PlanType } from '@/enums/planType';
import { Role } from '@/enums/roles';

import { getStatusTag } from '@/enums/PlanStatusEnum';

import PlansComments from '@/Components/CRM/Plans/Comments.vue';
import ContainerWrapperFull from '@/Components/Common/ContainerWrapperFull.vue';
import PlanHeading from '@/Components/Service/Plans/PlanHeadingWrapper.vue';
import PlanStatusLogs from '@/Components/CRM/Plans/StatusLogs.vue';
import ChangeNinjaModal from '@/Components/CRM/Plans/Modals/ChangeNinjaModal.vue';
import { executeAction, getPlanActionsForUser } from '@/composables/planActions';
import CrmPlanInfo from '@/Components/CRM/Plans/CrmPlanInfo.vue';
import LoadedFilesList from '@/Components/CRM/Apartments/LoadedFilesList.vue';
import ActionsButtons from '@/Components/CRM/Plans/ActionsButtons.vue';
import CrmPlan2dResults from '@/Components/CRM/Plans/CrmPlan2dResults/CrmPlan2dResults.vue';
import LinkedPlans from '@/Components/CRM/Apartments/LinkedPlans.vue';
import { getFilesAsNameAndUrl } from '@/utils/getFilesAsNameAndUrl';
import PlanMain from '@/Components/CRM/Plans/PlanMain.vue';

const setPlanData = (data) => ({
  crm_tags: data.crm_tags,
  crmTagsModified: data.crm_tags.map((tag) => tag.id),
  id: data.id,
  name: data.name,
  status: getStatusTag(data.status),
  ninja: data.ninja,
  cutter: data.cutter,
  manager: data.manager,
  pictures: data.pictures,
  links: data.links,
  description: data.description,
  plan_id: data.plan_id,
  apartment_id: data.apartment?.id || null,
  apartment_name: data.apartment?.name || null,
  apartment_files: getFilesAsNameAndUrl(data.apartment?.files || []),
  apartment_description: data.apartment?.description || null,
  apartmentLinkedPlans: data.apartment?.linked_plans?.filter((row) => row.includes(data.id)) || [],
  address: data.address,
  complex: data.complex,
  developer: data.developer,
  region: data.region,
  merged_from_plan_id: data.merged_from_plan_id,
  references: data.references,
});

const props = defineProps({
  id: {
    type: Number,
    default: () => null,
  },
});

const router = useRouter();
const route = useRoute();
const store = useStore();

const planData = ref({});

const planId = computed(() => Number(props.id || route.params.id));

const buttons = ref([]);

const loading = ref(true);

const samePlansVisible = ref(false);

const plan = computed(() => store.state.crm.currentPlan);

const authUser = computed(() => store.state.auth.currentUser);

const authUserRoles = computed(() => store.state.auth.currentUser.roles.map(({ name }) => name.toLowerCase()));

const isUserSupervisor = computed(() => authUserRoles.value.includes('supervisor'));
const isUserAdmin = computed(() => authUserRoles.value.includes('admin'));
const isUserNinja = computed(() => authUserRoles.value.includes('ninja'));

const closeModal = () => {
  samePlansVisible.value = false;
};

const loadData = () => {
  loading.value = true;
  return store
    .dispatch('crm/getCrmPlanById', planId.value)
    .then((resp) => {
      planData.value = setPlanData(resp);
      buttons.value = getPlanActionsForUser(PlanType.Type2d, planData.value, authUser.value);
    })
    .finally(() => (loading.value = false));
};

watchEffect(planId, (newId) => {
  if (newId && route.name === 'crm-plans-show') {
    loadData();
  }
  if (samePlansVisible.value) {
    closeModal();
  }
});

const changeName = (name) => {
  store.dispatch('crm/changePlanName', { id: planId.value, name }).finally(loadData);
};

const attachTagsToPlan = (tagsIds) => {
  loading.value = true;
  store.dispatch('crm/attachTagsToPlan', { id: planId.value, tagsIds }).finally(loadData);
};

const onButtonClick = (action) => {
  executeAction(action, planId.value, PlanType.Type2d, loadData, router);
};

onMounted(() => {
  loadData();
  store.dispatch('crm/getTagsList');
  store.dispatch('crm/getSimpleUsersList');
});

const tagsList = computed(() => store.state.crm.tagsList);
const type = PlanType.Type2d;

const canChangeNinja = computed(() => {
  const currentUserHasRoles = store.getters['auth/currentUserHasRoles'];

  if (currentUserHasRoles(Role.Admin)) {
    return true;
  }

  if (!currentUserHasRoles(Role.Manager)) {
    return false;
  }

  return store.state.crm.currentPlan.manager?.id === store.state.auth.currentUser.id;
});

const isSourcesArchiveCreating = computed(() => store.state.crm.apartments.isSourcesArchiveCreating);
const downloadSources = () => store.dispatch('crm/apartments/downloadSourcesArchive', planData.value?.apartment_id);

const compareCarousel = computed(() => ({
  originals: plan.value?.pictures || [],
  svgs: plan.value?.links?.svg_editor?.jpg || [],
  topViews: [],
}));

</script>

<style>
.ant-timeline-item-last {
  padding-bottom: 5px;
}

.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 0;
}

.status-history__user {
  display: block;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#plan-main {
  align-items: flex-start;
}

.plan-main-image {
  width: calc(100% / 3);
}

@media (max-width: 767px) {
  .plan-main-image {
    width: 100%;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.ant-modal {
  top: 0 !important;
  max-width: 100% !important;
}

</style>
